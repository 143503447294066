<template>
  <el-dialog 
    v-model="visible"
    :width="390"
    title=""
    custom-class="login_modal"
    :close-on-click-modal="false"
    :append-to-body="true"
    @close="$emit('close')">
      <h3>验证身份</h3>
      <p>为了保障您的账号安全，变更信息前需验证身份</p>
      <el-form
        ref="ruleFormRef"
        :model="formData"
        :rules="rules"
        class="demo-ruleForm"
        label-position="top"
        size="large"
      >
        <el-form-item label="验证方式" prop="type">
          <el-select v-model="formData.type" size="large">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <p v-if="formData.type == 0">若忘记密码，请更换验证方式。</p>
          <p v-if="formData.type == 1">验证码将发送至你原绑定的手机号码，若手机已不再使用，请更换验证方式。</p>
        </el-form-item>
        <el-form-item v-if="formData.type == 0" prop="password" label="密码">
          <el-input v-model="formData.password" type="password" placeholder="请输入密码" />
        </el-form-item>
        <el-form-item v-if="formData.type == 1" label="手机验证码" prop="code" class="need">
          <el-input v-model="formData.code" maxlength="6" placeholder="输入手机验证码" />
          <el-button v-if="seconds === 0"  type="primary" color="#0072EF" @click="sendCode">获取验证码</el-button>
          <el-button v-else type="primary" disabled color="#0072EF">{{ seconds }}s 后再发送</el-button>
        </el-form-item>
        <el-button type="primary" color="#0072EF" @click.enter="submitForm">验证身份</el-button>
      </el-form>
  </el-dialog>
</template>

<script>
import commonValidate  from '@/assets/js/commonValidate'
import md5 from 'js-md5'
export default {
  props: {
    visible: {
      type: Boolean
    }
  },
  watch: {
    '$store.state.userInfo': {
      handler(n) {
        if(n) {
          this.formData.phone = n.phone
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      formData: {
        type: 0,
        password: ''
      },
      message: '',
      options: [
        { label: '使用登录密码验证', value: 0 },
        { label: '使用手机验证码验证', value: 1 }
      ],
      seconds: 0,
    }
  },
  computed: {
    rules() {
      return this.formData.type == 0 ?  {
        password: [
          { validator: commonValidate.validEmpty1, message: '请输入密码', trigger: 'blur' },
          { validator: commonValidate.validatePassword, trigger: 'blur' },
        ],
      } : {
        code: [
          { validator: commonValidate.validEmpty1, message: '请输入验证码', trigger: 'blur' },
          { min: 6, message: '请输入6位数的验证码', trigger: 'blur' },
        ],
      }
    }
  },
  methods: {
    sendCode() {
      this.$refs.ruleFormRef.validateField('phone', (value) => {
        if(value) {
          if(this.sendCodeNum === 1) return;
          else this.sendCodeNum = 1;
          this.$API.PhoneMessage({
            phone: this.formData.phone,
            operate: 2
          }).then(res => {
            if(res.data.code == 200) {
              this.seconds = 60;
              let timer = setInterval(() => {
                this.seconds--;
              }, 1000);
              setTimeout(() => {
                clearInterval(timer);
                this.seconds = 0;
              }, 60000);
            }else {
              this.$message.error(res.data.msg)
            }
            this.sendCodeNum = 0
          })
        }
      })
      
    },
    submitForm() {
      this.$refs.ruleFormRef.validate((value, error) => {
        if(value) {
          let api = this.formData.type == 0 ? 'PasswordValidate' : 'PhoneValidate';
          let data = this.formData.type == 0 ? {
            password: md5(this.formData.password)
          } : {
            phone: this.formData.phone,
            code: this.formData.code,
          }
          this.$API[api](data, this.formData.type == 0 ? 3 : null).then(res => {
            if(res.data.code == 200) {
              this.formData = {
                type: 0,
                password: ''
              }
              this.$refs.ruleFormRef.resetFields()
              this.$emit('close', true)
            }else {
              this.$message.error(res.data.msg)
            }
          })
        }else {
          return;
        }
      })
    },
  }
}
</script>

<style lang='scss'>
  .login_modal {
    .el-dialog__body {
      padding: 0 30px 50px;

      & > h3 {
        line-height: 30px;
        font-size: 22px;
        color: #303133;
        margin-bottom: 5px;
      }

      & > p {
        font-size: 14px;
        color: #909399;
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .el-link {
          margin-left: 5px;
          color: #0072ef;
        }
      }

      .el-select {
        width: 100%;
      }

      .el-form-item--large .el-form-item__content {
        line-height: 50px;

        p {
          line-height: 18px;
          font-size: 12px;
          color: #909399;
          margin-top: 10px;
        }
      }

      .el-input--large .el-input__inner {
        height: 48px;
      }

      .el-button {
        width: 330px;
        height: 50px;
        border-radius: 4px;
        margin-top: 8px;
        margin-bottom: 0;
      }

      .el-alert {
        margin-bottom: 20px;
        color: #EB1948;
        padding: 10px 16px;
        line-height: 20px;
      }

      .need {
        .el-input--large { 
          width: 190px;
        }

        .el-button {
          width: 130px;
          margin-left: 10px;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }
</style>