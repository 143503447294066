<template>
	<el-dialog 
		v-model="visible"
		:width="390"
		title=""
		custom-class="choose_modal"
		:append-to-body="true"
		@close="$emit('close')">
		<h4 class="title">{{ data[type].title }}</h4>
		<div class="list">
			<div class="item" v-for="item in data[type].data" :key="item.icon" @click="eventClick(item.url)">
				<svg-icon class-name="first" :icon-class="item.icon" />
				<h4>{{ item.title }}</h4>
				<svg-icon class-name="right"  icon-class="arrow-right" />
			</div>
		</div>
	</el-dialog>
</template>

<script>
export default {
	props: {
    visible: {
      type: Boolean
    },
		type: {
			type: String
		}
  },
	data() {
		return {
			data: {
				'test': {
					title: '选择测评专题',
					data: [
						{ title: '标准化从业人员能力综合测试', url: 'https://tgik6tfkz3.jiandaoyun.com/f/6295b652f2fcae0009372016', icon: 'shapes' },
						{ title: '注册计量师资格考试专题', url: 'https://tgik6tfkz3.jiandaoyun.com/f/6547a0ed3610ba6c7b28b395', icon: 'chart' },
						{ title: '标准专题', url: 'https://tgik6tfkz3.jiandaoyun.com/f/6548aff99e90964c2ee5dab0', icon: 'standard_fill' },
					]
				},
				'grades': {
					title: '选择需要查询的测评专题成绩',
					data: [
						{ title: '标准化从业人员能力综合测试', url: 'https://tgik6tfkz3.jiandaoyun.com/f/629474fd377bb40008534ec2', icon: 'shapes' },
						{ title: '注册计量师资格考试专题', url: 'https://tgik6tfkz3.jiandaoyun.com/f/654af24f37d2753447b4731d', icon: 'chart' },
						{ title: '标准专题', url: 'https://tgik6tfkz3.jiandaoyun.com/f/654af252942d6b697f53d803', icon: 'standard_fill' },
					]
				}
			}
		}
	},
	methods: {
		eventClick(url) {
			window.open(url)
		}
	}
}
</script>

<style lang='scss'>
	.choose_modal {
		.el-dialog__header {
			height: 40px;
		}

		.el-dialog__body {
			padding: 0 30px 40px;
		}
		
		.title {
			font-size: 22px;
			color: #303133;
			margin-bottom: 20px;
		}

		.list {
			.item {
				display: flex;
				padding: 23px 15px;
				background: #f2f8ff;
				border-radius: 4px;
				align-items: center;
				margin-bottom: 10px;
				cursor: pointer;

				&:hover {
					background: #E8F2FF;
				}

				&:last-child {
					margin-bottom: 0;
				}

				.first {
					width: 24px;
					height: 24px;
					color: #0072EF;
					margin-right: 8px;
				}

				& > h4 {
					width: 250px;
					font-size: 14px;
					color: #303133;
					flex-shrink: 0;
				}

				.right {
					width: 18px;
					height: 18px;
					color: #A8ABB2;
				}
			}
		}
	}
</style>