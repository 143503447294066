<template>
  <div class="show_data">
    <div class="item" v-for="(item, index) in dataList" :key="index">
      <div>
        <h4>{{ item.title }}</h4>
        <p>{{ item.tip || detail[item.key] }}</p>
      </div>
      <el-button 
        :type="item.type || null" 
        :color="item.color || null"
        @click="eventClick(item)">{{ item.button }}</el-button>
    </div>
    <ChooseModal :visible="visible" type="grades" @close="visible = false" />
  </div>
</template>

<script>

import ChooseModal from '../../testReviews/components/chooseModal.vue'
export default {
  props: ['newData'],
  components: {
    ChooseModal
  },
  data() {
    return {
      dataList: [
        { title: '测评成绩查询', tip: '点击右侧按钮查询测评成绩', button: '查询成绩', type: 'primary', color: '#0072EF', url: 'https://tgik6tfkz3.jiandaoyun.com/f/629474fd377bb40008534ec2' },
        { title: '电子邮箱', key: 'email', button: '修改邮箱', status: 'email' },
        { title: '手机号码', key: 'phone', button: '修改手机', status: 'phone' },
        { title: '密码', key: 'password', tip: '点击右侧按钮修改密码', button: '修改密码', status: 'password' },
      ],
      detail: {
        email: 'ch***@qq.com',
        phone: '188****1234'
      },
      visible: false
    }
  },
  watch: {
    '$store.state.userInfo': {
      handler(n) {
        if(n) {
          this.detail.email = n.email.substr(0, 3) + '****' + n.email.substr(n.email.indexOf('@'));
          this.detail.phone = n.phone.substr(0, 3) + '****' + n.phone.substr(7);
        }
      },
      immediate: true
    },
    newData: {
      handler(n) {
        if(n) {
          this.detail.email = n.email ? n.email.substr(0, 3) + '****' + n.email.substr(n.email.indexOf('@')) : this.detail.email;
          this.detail.phone = n.phone ? n.phone.substr(0, 3) + '****' + n.phone.substr(7) : this.detail.phone
        }
      },
      immediate: true
    }
  },
  methods: {
    eventClick(item) {
      if(item.url) {
        this.visible = true
        // window.open(item.url)
      }else this.$emit('update', item.status)
    }
  }
}
</script>

<style lang='scss'>
  .show_data {
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 10px;
    padding: 10px 0;

    .item {
      padding: 30px 0;
      display: flex;
      align-items: center;
      margin: 0 60px;
      border-bottom: 1px solid #EBEBEB;

      &:last-child {
        border-bottom: 0;
      }

      & > div {
        width: calc(100% - 96px);

        & > h4 {
          font-size: 16px;
          color: #303133;
          line-height: 22px;
          margin-bottom: 6px;
        }

        & > p {
          line-height: 20px;
          font-size: 14px;
          color: #909399;
        }
      }

      .el-button {
        width: 96px;
        height: 36px;
        border-radius: 4px;

        &:focus, &:hover {
          color: #0072EF;
          border-color: #0072EF;
        }
      }
      
      .el-button--primary {
        &:focus, &:hover {
          color: #fff;
          border-color: var(--el-button-hover-border-color);
        }
      }
    }
  }
</style>  