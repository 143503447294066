<template>
  <div class="update_data">
    <div class="content">
      <div class="form">
        <div class="title"><span></span> {{ updateType == 'email' ? '修改电子邮箱' : updateType == 'phone' ? '修改手机号码' : '修改密码' }}</div>
        <el-form
          ref="ruleFormRef"
          :model="formData"
          :rules="updateType == 'email' ? rules : updateType == 'phone' ? rules1 : rules2"
          class="demo-ruleForm"
          label-width="100px"
          size="large"
        >
          <el-form-item v-if="updateType == 'email'" label="电子邮箱:" prop="email">
            <el-input v-model="formData.email" placeholder="输入新电子邮箱" />
          </el-form-item>
          <el-form-item v-if="updateType == 'phone'" label="手机号码:" prop="phone">
            <el-input v-model="formData.phone" placeholder="输入新手机号码" />
          </el-form-item>
          <el-form-item v-if="updateType == 'phone'" label="验证码:" prop="code" class="need">
            <el-input v-model="formData.code" maxlength="6" placeholder="输入手机验证码" />
            <el-button v-if="seconds === 0"  type="primary" color="#0072EF" @click="sendCode">获取验证码</el-button>
            <el-button v-else type="primary" disabled color="#0072EF">{{ seconds }}s 后再发送</el-button>
          </el-form-item>
          <el-form-item v-if="updateType == 'password'" label="新密码:" prop="password">
            <el-input v-model="formData.password" type="password" placeholder="输入新密码" />
          </el-form-item>
          <el-form-item v-if="updateType == 'password'" label="确认密码:" prop="comPassword">
            <el-input v-model="formData.comPassword" type="password" placeholder="再次输入密码" />
          </el-form-item>
          <el-form-item label="">
            <el-button type="primary" color="#0072EF" @click="submitForm">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="updateType != 'email'" class="tip">
        <div>注意事项：</div>
        <template v-if="updateType == 'phone'">
          <p>1、仅支持中国大陆地区手机号码注册；</p>
          <p>2、如果您未收到手机验证码，请检查填写的手机号码是否正确，并查看是否被安全软件误拦截；</p>
          <p>3、一个手机号码每天只允许获取 5 次验证码，若因环境不佳而未能及时接收验证码短信，请勿频繁请求，可稍后再试。</p>
        </template>
        <p v-if="updateType == 'password'">密码支持 6-16 位字符，并且可以包括大写字母、小写字母和数字的任意组合；</p>
      </div>
    </div>
  </div>
</template>

<script>
import CommonBread from '@/components/CommonBread'
import commonValidate from '@/assets/js/commonValidate'
import md5 from 'js-md5'
export default {
  props: ['updateType'],
  components: {
    CommonBread
  },
  data() {
    var validatePasswordCom = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.formData.password) {
        callback(new Error('两次输入密码不一致，请重新输入'));
      } else {
        callback();
      }
    };
    return {
      dataList: [
        { title: '个人中心' },
      ],
      formData: {
        email: '',
        phone: '',
        code: '',
        password: '',
        comPassword: '',
        checked: true
      },
      sendCodeNum: 0,
      seconds: 0,
      rules: {
        email: [
          { validator: commonValidate.validEmpty1, message: '请输入新电子邮箱', trigger: 'blur' },
          { validator: commonValidate.validateEmail, trigger: 'blur' },
        ],
      },
      rules1: {
        phone: [
          { validator: commonValidate.validEmpty1, message: '请输入新手机号码', trigger: 'blur' },
          { validator: commonValidate.validPhone, trigger: 'blur' },
        ],
        code: [
          { validator: commonValidate.validEmpty1, message: '请输入验证码', trigger: 'blur' },
          { min: 6, message: '请输入6位数的验证码', trigger: 'blur' },
        ],
      },
      rules2: {
         password: [
          { validator: commonValidate.validEmpty1, message: '请输入密码', trigger: 'blur' },
          { validator: commonValidate.validatePassword1, trigger: 'blur' },
        ],
        comPassword: [
          { validator: commonValidate.validEmpty1, message: '请再次输入密码', trigger: 'blur' },
          { validator: validatePasswordCom, trigger: 'blur' },
        ],
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs.ruleFormRef.validate((value) => {
        if(value) {
          let api = this.updateType == 'email' ? 'UpdateEmail' : this.updateType == 'phone' ? 'UpdatePhone' : 'UpdatePassword'
          let data = this.updateType == 'email' ? {
            email: this.formData.email
          } : this.updateType == 'phone' ? {
            phone: this.formData.phone,
            code: this.formData.code
          } : {
            password: md5(this.formData.password),
            confirmPassword: md5(this.formData.comPassword),
            phone: this.$store.state.userInfo.phone,
          };
          this.$API[api](data, 3).then(res => {
            if(res.data.code == 200) {
              this.$message.success('修改成功')
              this.$emit('success', this.updateType == 'email' ? { type: this.updateType , email: this.formData.email} :
              {type: this.updateType , phone: this.formData.phone});
            }else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          return;
        }
      })
    },
    sendCode() {
      this.$refs.ruleFormRef.validateField('phone', (value) => {
        if(value) {
          // this.$API.PhoneInit({
          //   phone: this.formData.phone
          // }).then(res => {
          //   if(res.data.code == 200) {
              
              if(this.sendCodeNum === 1) return;
              else this.sendCodeNum = 1;
              this.$API.PhoneMessage({
                phone: this.formData.phone,
                operate: 1
              }).then(res => {
                if(res.data.code == 200) {
                  this.seconds = 60;
                  let timer = setInterval(() => {
                    this.seconds--;
                  }, 1000);
                  setTimeout(() => {
                    clearInterval(timer);
                    this.seconds = 0;
                  }, 60000);
                }else {
                  this.$message.error(res.data.msg)
                }
                this.sendCodeNum = 0
              })
          //   }else {
          //     this.$message.error(res.data.msg)
          //   }
          // })
          
        }
      })
    }
  }
}
</script>

<style lang='scss'>
  .update_data {
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 10px;

    .content {
      padding: 60px;
      display: flex;
      align-items: stretch;

      .form {
        width: 746px;

        .title {
          display: flex;
          font-size: 18px;
          color: #0072ef;
          line-height: 26px;
          font-weight: 600;
          align-items: center;
          margin-bottom: 30px;

          & > span {
            width: 6px;
            height: 26px;
            background: #0072ef;
            display: block;
            margin-right: 10px;
          }
        }

        .el-form-item__label {
          padding-right: 20px;
        }

        .diff {
          .el-form-item__content {
            line-height: 20px;
          }
          .el-checkbox.el-checkbox--large {
            height: 20px;;
          }

          .el-checkbox__input.is-checked .el-checkbox__inner {
            background-color: #0072EF;
            border-color: #0072EF;
          }

          .el-checkbox {
            margin-right: 5px;
          }

          .el-checkbox__inner {
            border-radius: 50%;
          }

          .tip1 {
            color: #909399;
            font-size: 12px;
          }

          .el-link {
            color: #0072EF;
            font-size: 12px;
          }
        }

        .el-button {
          width: 130px;
          height: 40px;
          border-radius: 4px;
          margin-top: 10px;
        }

        .need {
          .el-input--large { 
            width: 443px;
          }

          .el-button {
            margin-left: 10px;
            margin-top: 0!important;
          }
        }
      }

      .tip {
        width: 320px;
        flex-shrink: 0;
        border-left: 1px solid #EBEEF5;
        margin-left: 55px;
        padding-left: 55px;

        & > div {
          font-size: 16px;
          color: #c0c4cc;
          line-height: 36px;
          margin-bottom: 30px;
        }

        & > p {
          font-size: 14px;
          color: #606266;
          line-height: 24px;
          margin-bottom: 18px;
        }
      }
    }
  }
</style>