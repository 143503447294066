<template>
  <div id="personCenter">
    <CommonBread :dataList="dataList"></CommonBread>
    <component :is="panel" :updateType="updateType" :newData="newData" @update="updateClick" @success="successClick"></component>
    <VerificationIdentityModal :visible="visible" @close="closeClick"></VerificationIdentityModal>
  </div>
</template>

<script>
import ShowData from './components/ShowData.vue'
import UpdateData from './components/UpdateData.vue'
import CommonBread from '@/components/CommonBread'
import VerificationIdentityModal from './components/VerificationIdentityModal.vue'
export default {
  components: {
    CommonBread,
    UpdateData,
    ShowData,
    VerificationIdentityModal
  },
  data() {
    return {
      dataList: [
        { title: '个人中心' },
      ],
      updateType: null,
      visible: false,
      panel: 'ShowData',
      newData: null
    }
  },
  methods: {
    updateClick(data) {
      console.log(this.visible)
      this.updateType = data;
      this.visible = true;
    },
    closeClick(type) {
      if(type == true) {
        this.visible = false;
        this.panel = 'UpdateData';
      }
      this.visible = false;
      // else {
      //   this.panel = 'ShowData';
      //   this.updateType = null;
      //   this.visible = false;
      // }
    },
    successClick(data = null) {
      this.panel = 'ShowData';
      if(data.type) {
        this.newData = data
      }
    }
  }

}
</script>

<style lang='scss'>
  #personCenter {
    width: 1200px;
    margin: 0 auto;
    padding: 0 10px;

    .el-breadcrumb__inner {
      font-size: 14px;
      font-weight: 600;
      color: #303133;
    }
  }
</style>